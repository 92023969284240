
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VariableSizeList } from 'react-window';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Checkbox, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from "@mui/material/styles";

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';


import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


function RUTab() {

    const options = [
        { label: 'Скидка 10-30% на 14 дней', value: '10-30% 14 days', conditions: true },
        { label: 'Промокод на 200 рублей на 14 дней', value: '200 rub 14 days' },
        { label: 'Промокод на пиццу 25-35 см на 14 дней ', value: 'pizza 25-35 cm 14 days' },
        { label: 'Отчеты', value: 'reports' }
    ];

    const [value, setValue] = React.useState(null);

    const rteRef = useRef(null);
    const theme = useTheme()
    const [editBoxValue, setEditBoxValue] = useState("Компенсация")


    function onChangeRich(data) {
        setValue(data);
    }

    return (
        <>
            <Paper sx={{ overflowY: "scroll", minHeight: '100%', maxHeight: '100%', padding: '10px' }}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={editBoxValue}
                    onChange={(event, newValue) => setEditBoxValue(newValue)}
                    renderInput={(params) => <TextField {...params} label="Промокод" variant="outlined" />}
                />

                <Box sx={{ padding: "10px" }}>


                </Box>
            </Paper>
        </>
    );
}

function KZTab() {

    return (
        <>
            <Paper sx={{ overflowY: "scroll", minHeight: '100%', maxHeight: '100%' }}>

            </Paper>
        </>
    );
}

function getMenuContent(tab) {
    if (tab === 'ru') return <RUTab />;
    if (tab === 'kz') return <KZTab />;
}


function CompenstationsApp() {
    const [visible, setVisible] = useState(true);
    const [tabMenu, switchTab] = React.useState('ru');

    const onTabSwitching = (event, newValue) => {
        switchTab(newValue);
    };

    return (
        <>
            <BottomNavigation sx={{ width: "100%", height: 52 }} value={tabMenu} onChange={onTabSwitching}>
                <BottomNavigationAction size="small" label="Россия" value="ru" icon={<AccessibilityIcon />} />
                <BottomNavigationAction size="small" label="Казахстан" value="kz" icon={<AdminPanelSettingsIcon />} />
            </BottomNavigation>
            {getMenuContent(tabMenu)}
        </>
    );
}



export default CompenstationsApp;