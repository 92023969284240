
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Dialog, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CatList = {
    "С сердечками": [
        "https://i.ibb.co/VTdTh7F/cat.jpg",
        "https://i.ibb.co/Y3DqvTj/cat2.jpg",
        "https://i.ibb.co/xY6mRHn/cat3.jpg",
        "https://i.ibb.co/vxnDVnL/cat4.jpg",
        "https://i.ibb.co/DQMQbFp/cat5.jpg",
        "https://i.ibb.co/KzmpnBT/cat6.jpg",
        "https://i.ibb.co/8NCX3YY/cat7.jpg",
        "https://i.ibb.co/sbYxMRh/cat8.jpg",
    ],
    "Мемные": [
        "https://i.ibb.co/0mmY2HX/catmem2.png",
        "https://i.ibb.co/D7dRFZ1/catmem.png",
        "https://i.ibb.co/DDxtyRz/catmem3.png",
        "https://i.ibb.co/C6062jH/catmem4.jpg"
    ],
    "Милые": [
        "https://i.ibb.co/NT3ZjNF/catcute2.jpg",
        "https://i.ibb.co/cwcpkDC/catcute.jpg",
    ],
    "На работе": [
        "https://i.ibb.co/528NPzW/catwork4.png",
        "https://i.ibb.co/kJNvtdq/catwork3.jpg",
        "https://i.ibb.co/XY1BNdM/catwork2.png",
        "https://i.ibb.co/p16Y1jf/catwork.png",
    ],
    "Не коты": [
        "https://i.ibb.co/8cv0031/photo-2024-10-29-23-30-30.jpg",
    ]
}

function Cats() {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleClickOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ width: "100%" }}>
            {Object.entries(CatList).map(([category, images], index) => (
                <Accordion disableGutters key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                            {images.map((image, idx) => (
                                <Box
                                    key={idx}
                                    component="img"
                                    src={image}
                                    alt={`cat-${idx}`}
                                    sx={{ width: 100, height: 100, cursor: "pointer" }}
                                    onClick={() => handleClickOpen(image)}
                                />
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                    <Box
                        component="img"
                        src={selectedImage}
                        alt="Выбрать котика"
                        sx={{ maxWidth: "100%", maxHeight: "80vh" }}
                    />
                </Box>
            </Dialog>
        </Box>
    );
}

export default Cats;