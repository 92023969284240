export const isAuthenticated = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return false;
    }
    
    const payload = JSON.parse(atob(token.split('.')[1]));
    const isExpired = Date.now() >= payload.exp * 1000;

    return !isExpired;
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
  };