
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";



function Greetings(props) {
    const [name, setName] = useState("");
    const [selectedGreeting, setSelectedGreeting] = useState("");
    const [selectedHold, setSelectedHold] = useState("");

    const options = [
        { label: "Здравствуйте", key: "1" },
        { label: "Добрый день", key: "2" },
        { label: "Добрый вечер", key: "3" },
        { label: "Доброе утро", key: "4" },
        { label: "Доброй ночи", key: "5" },
        { label: "Привет", key: "6" },
    ];

    const holds = [
        { label: "Выясним детали и вернёмся, 5-10 минут", key: "1", tag: "Детали" },
        { label: "Постараемся решить вопрос, вернёмся с ответом в течение 5-10 минут", key: "2", tag: "Решим вопрос" },
        { label: "Проверим информацию в заказе и вернёмся в течение 5-10 минут", key: "3", tag: "Заказ" },
        { label: "Постараемся изменить адрес доставки, вернёмся через 5-10 минут", key: "4", tag: "Адрес" },
        { label: "Изучим детали переписки и постараемся найти решение, 5−10 минут", key: "5", tag: "Переписка" },
        { label: "Пожалуйста, дайте нам 5-10 минут, проверим меню и вернёмся", key: "6", tag: "Меню" },
        { label: "Хотим помочь: проверим условия акции и вернёмся в течение 5-10 минут", key: "7", tag: "Условия акции" },
        { label: "Сгенерируем промокод и вернёмся в течение 5-7 минут", key: "8", tag: "Генерация промо" },
        { label: "Свяжемся с менеджером и вернёмся в течение 5-7 минут", key: "9", tag: "Звонок менеджеру" },
        { label: "Проверим доступность доставки, вернёмся через 5-10 минут", key: "104", tag: "Стоп/Вне зоны" },
        { label: "Направим обращение коллегам и подберём решение, пожалуйста, дайте нам 5-10 минут ", key: "11", tag: "Обращение коллегам" },
        { label: "Составим обращение в ресторан и подберём решение, пожалуйста, дайте нам 5-10 минут", key: "12", tag: "Обращение в ресторан" },

        // { label: "Проверим информацию и вернёмся в течение 5-10 минут", key: "4" },
        // { label: "Составим обращение и вернёмся в течение 5-10 минут", key: "7" },
        // { label: "Ознакомимся с проблемой, 5-10 минут", key: "9" },
        // { label: "Ознакомимся с проблемой, 5-7 минут", key: "8" },
        // { label: "Составим обращение и вернёмся в течение 5-7 минут", key: "6" },
        // { label: "Проверим информацию и вернёмся в течение 5-7 минут", key: "5" },
        // { label: "5-7 минут, во всём разберёмся", key: "1" },
        // { label: "5-7 минут и вернёмся с ответом ", key: "2" },
        // { label: "5-7 минут, свяжемся с менеджером пиццерии", key: "3" },

        // { label: "Ознакомимся с проблемой, 10-15 минут", key: "10" },
    ];

    const longMsgs = [
        { label: "Извините за долгий ответ, чат перегружен.", key: "2" },
        { label: "Простите за долгий ответ, поступает много обращений.", key: "1" },
        { label: "Извините за долгий ответ. Нужно ещё немного времени, чтобы решить вопрос.", key: "3" },
        { label: "Извините, что приходится ждать, ещё разбираемся в проблеме.", key: "3" },
        { label: "Извините за ожидание, вопрос оказался сложнее, чем мы думали.", key: "3" },
    ]

    const nextMsgs = [
        // { label: "Спасибо, возвращаемся с решением.", key: "1" },
        { label: "Спасибо, что подождали.", key: "2" },
        { label: "Благодарим за терпение.", key: "3" },
        { label: "Благодарим за ожидание.", key: "4" },
        { label: "Спасибо, что подождали ответ.", key: "5" },

        { label: "Извините, что задержались с ответом.", key: "6" },
        { label: "Простите, что приходится ждать.", key: "7" },
        { label: "Простите, что задержались, искали оптимальное для вас решение.", key: "8" },
        { label: "Извините, не хотели заставлять вас ждать.", key: "9" },
    ]

    const endMsgs = [
        { label: "Если будут другие вопросы или трудности — мы рядом.", key: "1" },
        { label: `Рады были помочь. Если будут другие вопросы или трудности — мы рядом.\nДля нас важно ваше мнение, пожалуйста, оцените качество консультации, чтобы мы могли стать ещё лучше.`, key: "2" },
        { label: "Рады были помочь. Если будут другие вопросы или трудности — мы рядом.", key: "3" },

        { label: "Рады были ответить. Если будут другие вопросы или трудности — мы рядом.", key: "4" },

        { label: `Передали информацию коллегам, во всём разберёмся и свяжемся с вами в течение суток.`, key: "5" },
        { label: `Передали информацию в техническую поддержку, во всём разберёмся и свяжемся с вами, когда получим ответ.`, key: "6" },
        { label: `Передали информацию коллегам, начислим додокоины или свяжемся с вами в течение суток.`, key: "7" },

        { label: "Будем ждать ответ.", key: "8" },
        { label: "Будем ждать ответ, хотим разобраться в проблеме и помочь.", key: "9" },
        { label: "Будем ждать вашего решения.", key: "10" },
        { label: "Если проблема останется, напишите нам — составим запрос в техническую поддержку", key: "11" },
    ]

    const handleNameChange = (event) => {
        const newName = event.target.value
            .replace(/\s+/g, ' ')
            .trim()
            .toLowerCase()
            .replace(/(^|\s)\S/g, letter => letter.toUpperCase());
        setName(newName);
        props.setGreetingText(`${selectedGreeting}${name !== "" ? ", " + name + "!" : selectedGreeting !== "" ? "!" : ""}`);
    };

    const handleGreetingChange = (event, value) => {
        if (value) {
            setSelectedGreeting(value.label);
            props.setGreetingText(`${selectedGreeting}${name !== "" ? ", " + name + "!" : selectedGreeting !== "" ? "!" : ""}`);
        } else setSelectedGreeting("")
    };


    const handleLongMsgChange = (event, value) => {
        if (value) {
            props.setLongMsg(`${value.label}`);
        } else {
            props.setLongMsg("");
        }
    };


    const handleHoldChange = (event, value) => {
        if (value) {
            setSelectedHold(value.label);
            props.setHoldText(`${value.label}`);
        } else {
            setSelectedHold(null);
            props.setHoldText("");
        }
    };

    const handleNextMsgChange = (event, value) => {
        if (value) {
            props.setNextMsg(`${value.label}`);
        } else {
            props.setNextMsg("");
        }
    };

    const handleEndMsgChange = (event, value) => {
        if (value) {
            props.setEndMsg(`${value.label}`);
        } else {
            props.setEndMsg("");
        }
    };

    useEffect(() => {
        props.setGreetingText(`${selectedGreeting}${name !== "" ? ", " + name + "!" : selectedGreeting !== "" ? "!" : ""}`);
    }, [selectedGreeting, name]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
            <TextField
                id="outlined-basic"
                label="Имя"
                autoComplete="off"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
            />
            <Autocomplete
                sx={{ width: "300px" }}
                options={options}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                getOptionLabel={(option) => option.label}
                onChange={handleGreetingChange}
                renderInput={(params) => <TextField {...params} label="Приветствие" />}
            />
            <Autocomplete
                sx={{ width: "300px" }}
                options={longMsgs}
                getOptionLabel={(option) => option.label}
                onChange={handleLongMsgChange}
                renderInput={(params) => <TextField {...params} label="Долгий ответ" />}
            />
            <Autocomplete
                sx={{ width: "300px" }}
                options={holds}
                getOptionLabel={(option) => option.label}
                onChange={handleHoldChange}

                renderOption={(props, option) => (
                    <Tooltip title={option.label} arrow placement='right'>
                        <Box component="li" {...props} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Chip
                                label={option.tag}
                                size="small"
                                sx={{ backgroundColor: option.id ? 'green' : 'default', color: 'white' }}
                            />
                            <span style={{ paddingLeft: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {`${option.label}`}
                            </span>
                        </Box>
                    </Tooltip>
                )}
                renderInput={(params) => <TextField {...params} label="Холд" />}
            />
            <Autocomplete
                sx={{ width: "300px" }}
                options={nextMsgs}
                getOptionLabel={(option) => option.label}
                onChange={handleNextMsgChange}
                renderInput={(params) => <TextField {...params} label="После холда" />}
            />
            <Autocomplete
                sx={{ width: "300px" }}
                options={endMsgs}
                getOptionLabel={(option) => option.label}
                onChange={handleEndMsgChange}
                renderInput={(params) => <TextField {...params} label="Завершение" />}
            />
        </Box>
    );
}


export default Greetings;