
import React, { useState, useReducer, useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VariableSizeList } from 'react-window';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from "@mui/material/styles";

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
// import { FixedSizeList as List } from 'react-window';
import debounce from 'lodash.debounce';

import axios from 'axios';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';




function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            const length = 8;
            const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let pass = "";

            for (var i = 0, n = charset.length; i < length; ++i) {
                pass += charset.charAt(Math.floor(Math.random() * n));
            }

            const response = await axios.post('https://dodogenerator.pacificrp.ru/register', { username, password: pass },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

            //localStorage.setItem('token', response.data.token);
            //window.location.reload();
            setMessage(`https://dodogenerator.pacificrp.ru  
                Данные для входа
                Логин: ${username} 
                Пароль: ${pass}`
            );
        } catch (error) {
            setMessage('Ошибка авторизации');
        }
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}>
                <Typography variant="h6" gutterBottom>
                    DODOGENERATOR — Регистрация
                </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Имя пользователя"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Button color="primary" type="submit" fullWidth>
                    Зарегистрировать
                </Button>
            </form>
            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                {message && <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{message}</Typography>}
            </Box>

        </Container>
    );
}



export default Register;


