
import React, { useState, useReducer, useEffect, useMemo, useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VariableSizeList } from 'react-window';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from "@mui/material/styles";

import { Dialog, DialogContent } from '@mui/material';

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
// import { FixedSizeList as List } from 'react-window';
import debounce from 'lodash.debounce';
import axios from 'axios';

import UserTemplates from './UserTemplates';
import Errors from './Errors';
import Promocodes from './Promocodes';
import Greetings from './Greetings';
import Templates from './Templates';
import Register from './Register';
import Cats from './Cats';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PetsIcon from '@mui/icons-material/Pets';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ImageIcon from '@mui/icons-material/Image';

import { jwtDecode } from 'jwt-decode';

function Generator() {

    const [isDialogOpen, setIsDialogOpen] = useState(false);


    const [key, setKey] = useState(0);

    const [greetingText, setGreetingText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [templateText, setTemplateText] = useState('');
    const [holdText, setHoldText] = useState('');
    const [longMsg, setLongMsg] = useState('');
    const [promoText, setPromoText] = useState('');
    const [nextMsg, setNextMsg] = useState('');
    const [endMsg, setEndMsg] = useState('');
    const [errorCount, setErrorCount] = useState(0);
    const [combinedText, setCombinedText] = useState('');
    const [templateImg, setTemplateImg] = useState('');


    const handleClear = () => {
        setCombinedText("");
        setErrorCount(0);
        setPromoText("");
        setLongMsg("");
        setHoldText("");
        setTemplateText("");
        setErrorText("");
        setNextMsg("");
        setEndMsg("");
        setGreetingText("");
        setKey((prevKey) => prevKey + 1);
    };

    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(combinedText)
            .then(() => console.log('Text copied to clipboard'))
            .catch(err => console.error('Failed to copy text: ', err));
    }, [combinedText]);

    const handleTextChange = useCallback((e) => {
        setCombinedText(e.target.value);
    }, []);

    const handleOpenImage = () => {
        setIsDialogOpen(true);
    };

    const handleCloseImage = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const combined = `${nextMsg} ${greetingText} ${longMsg} ${errorText} ${templateText} ${holdText} ${promoText !== "" ? "\n" + promoText : ""} ${endMsg}`
            .replace(/[ \t]+/g, ' ')
            .trim()
            .replace(/\.$/, '');
        setCombinedText(combined);
    }, [greetingText, longMsg, errorText, templateText, holdText, promoText, nextMsg, endMsg]);

    return (
        <Box sx={{ margin: '15px' }} key={key}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Greetings
                    key={`greetings-${key}`}
                    setGreetingText={(text) => setGreetingText(text)}
                    setHoldText={(text) => setHoldText(text)}
                    setLongMsg={(text) => setLongMsg(text)}
                    setNextMsg={(text) => setNextMsg(text)}
                    setEndMsg={(text) => setEndMsg(text)} />
            </Box>
            <Divider></Divider>
            <Box sx={{ marginTop: '30px' }}>
                <Errors
                    key={`errors-${key}`}
                    setErrorText={(text) => setErrorText(text)}
                    setErrorCount={(text) => setErrorCount(text)} />
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <Templates
                    key={`templates-${key}`}
                    setTemplateText={(text) => setTemplateText(text)}
                    setTemplateImg={(text) => setTemplateImg(text)} />
            </Box>
            <Promocodes
                key={`promocodes-${key}`}
                setPromoText={(text) => setPromoText(text)} />

            <Box sx={{ maxWidth: 'auto', margin: "auto", textAlign: "center" }}>
                <Box sx={{ marginTop: "50px" }}>
                    <Paper sx={{ padding: "10px" }}>
                        <TextField
                            sx={{
                                width: "90%",
                                '& .MuiInputBase-input': {
                                    fontSize: '24px',
                                }
                            }}
                            multiline
                            size='small'
                            maxRows={10}
                            value={combinedText}
                            onChange={handleTextChange}>
                        </TextField>
                        {/* <Typography variant="h6">{`${greetingText} ${longMsg} ${errorText} ${templateText} ${holdText}`}</Typography>
                        <Typography variant="h6">{`${promoText}`}</Typography> */}
                    </Paper>
                    <ButtonGroup sx={{ marginTop: "5px" }}>
                        <Button startIcon={<ContentCopyIcon />} onClick={handleCopyToClipboard}>Копировать текст</Button>
                        <Button startIcon={<DeleteForeverIcon />} onClick={handleClear}>Сбросить форму</Button>
                        {templateImg !== "" && (
                            <>
                                <Button startIcon={<ImageIcon />} onClick={handleOpenImage}>Открыть изображение</Button>

                                <Dialog open={isDialogOpen} onClose={handleCloseImage} maxWidth="md" fullWidth>
                                    <DialogContent>
                                        <img src={templateImg} alt="Template" style={{ width: '100%' }} />
                                    </DialogContent>
                                </Dialog>
                            </>
                        )
                            ||
                            <>
                                <Button onClick={handleOpenImage} startIcon={<PetsIcon />}>Котики</Button>

                                <Dialog open={isDialogOpen} onClose={handleCloseImage} maxWidth="md" fullWidth>
                                    <DialogContent>
                                        <Cats />
                                    </DialogContent>
                                </Dialog>
                            </>

                        }
                    </ButtonGroup>
                </Box>
            </Box>
        </Box>
    );
}

function getMenuContent(tab) {
    if (tab === 'generator') return <Generator />;
    if (tab === 'templates') return <UserTemplates />;
    if (tab === 'register') return <Register />;
}


function GeneratorApp() {
    const [visible, setVisible] = useState(true);
    const [tabMenu, switchTab] = React.useState('generator');
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.id;
                setUserId(userId)
                console.log('User ID:', userId);
            } catch (error) {
                console.error('Error jwt:', error);
            }
        }
    }, [])



    const onTabSwitching = (event, newValue) => {
        switchTab(newValue);
    };

    return (
        <>
            <BottomNavigation sx={{ width: "100%", height: 52 }} value={tabMenu} onChange={onTabSwitching}>
                <BottomNavigationAction size="small" label="Генератор" value="generator" icon={<AutoFixHighIcon />} />
                <BottomNavigationAction size="small" label="Шаблоны" value="templates" icon={<ChatBubbleIcon />} />
                {userId === 1 && <BottomNavigationAction size="small" label="Регистрация" value="register" icon={<AdminPanelSettingsIcon />} />}
            </BottomNavigation>
            {getMenuContent(tabMenu)}
        </>
    );
}



export default GeneratorApp;