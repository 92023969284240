import React, { useState, useReducer, useEffect, useMemo } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Checkbox, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';

import { GlobalStyles, createTheme, ThemeProvider } from '@mui/material';
import { isAuthenticated, logout } from './utils/auth';
import { useTheme } from '@emotion/react';

import CompenstationsApp from './apps/Сompensations'
import GeneratorApp from './apps/Generator'
import Auth from './apps/Auth'

window.addEventListener('error', event => {
  if (event.message.includes('ResizeObserver loop')) {
    event.stopImmediatePropagation();
  }
});

function App() {
  const [selectedApp, setSelectedApp] = useState('handling');


  const theme = createTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 32,
            minHeight: 32,
            backgroundColor: 'rgb(246, 127, 0)',
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '14px',
            backgroundColor: "rgba(0, 0, 0, 0.30)"
          }
        }
      }
    },
    palette: {
      type: 'dark',
      mode: 'dark',
      background: {
        default: 'rgba(0, 0, 0, 0.80)',
        paper: 'rgba(49, 49, 54, 0.60)'
      },
      text: {
        primary: 'rgba(233, 233, 233, 1)',
      },
      primary: {
        light: 'rgba(209, 209, 209, 1)',
        main: 'rgba(209, 209, 209, 1)',
        dark: 'rgba(209, 209, 209, 1)',
        contrastText: '#000',
      },
      secondary: {
        light: 'rgba(209, 209, 209, 1)',
        main: 'rgba(209, 209, 209, 1)',
        dark: 'rgba(209, 209, 209, 1)',
        contrastText: '#000',
      }
    },
    typography: {
      fontFamily: ["Onest", "sans"].join(","),
      common: { color: 'rgba(233, 233, 233, 1)' },
      body1: { color: 'rgba(233, 233, 233, 1)' },
      body2: { color: 'rgba(233, 233, 233, 1)' },
      caption: { color: 'rgba(233, 233, 233, 1)' },
      h1: { color: 'rgba(233, 233, 233, 1)' },
      h2: { color: 'rgba(233, 233, 233, 1)' },
      h3: { color: 'rgba(233, 233, 233, 1)' },
      h4: { color: 'rgba(233, 233, 233, 1)' },
      h5: { color: 'rgba(233, 233, 233, 1)' },
      h6: { color: 'rgba(233, 233, 233, 1)' },
    },
    overrides: {
      MuiButton: {
        root: {
          color: 'rgba(233, 233, 233, 1)',
        },
      },
    },
  })


  const renderSelectedApp = () => {
    switch (selectedApp) {
      case 'compensations':
        return <CompenstationsApp />;
      case 'handling':
        return <GeneratorApp />;
      default:
        return <GeneratorApp />;
    }
  };

  const renderAuth = () => {
    return <Auth/>
  }

  return (
    <>
      <GlobalStyles
        styles={{
          "@font-face": {
            fontFamily: 'Digital-7',
            src: 'url(build/fonts/digital-7.ttf) format("truetype")'
          },
          'body': { overflow: "hidden", padding: 0, margin: 0, backgroundColor: 'rgba(26, 26, 31)' },
          '*::-webkit-scrollbar': { width: '0.3em' },
          '*::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.1)' },
          '*::-webkit-scrollbar-corner': { display: 'none' },
          '.menububble': {
            position: 'absolute',
            zIndex: 999999,
          }
        }}
      />
      <ThemeProvider theme={theme}>
        <Paper sx={{height: "1000px"}}>
          {isAuthenticated() ? renderSelectedApp() : renderAuth()}
        </Paper>
      </ThemeProvider>
    </>
  );
}

export default App;
