
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";


function Promocodes(props) {
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [country, setCountry] = useState("ru");
    const [conditions, setConditions] = useState(false);
    const [offerType, setOfferType] = useState(true);

    const promos = {
        ru: [
            { label: "10-30% на 14 дней", key: "1", a: "1", text: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "200-300 рублей на 14 дней", key: "2", a: "2", text: "на 200 рублей, им можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 200 рублей, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "Пицца 25-35 см на 14 дней", key: "3", a: "3", text: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Продукт можно получить за рубль, если его стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Промокод суммируется с комбо, но не работает с другими акциями.", textCondition: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить за рубль к заказу на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, предложение суммируется с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе с подарочным продуктом должна быть выше минимальной на доставку." },
            { label: "Додстер на 14 дней", key: "5", a: "7", text: "на классический додстер — закуску можно получить бесплатно 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё. Додстер нужно добавить в корзину самостоятельно, затем применить купон. Промокод суммируется с комбо, но не работает с другими акциями.", textCondition: "на классический додстер, который можно получить бесплатно 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё. Чтобы купон сработал, сумма заказа вместе с подарочной закуской должна быть выше минимальной на доставку. Промокод суммируется с комбо, но не действует с другими акциями." },
            { label: "100 рублей на год", key: "6", a: "8", text: "на 100 рублей. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 100 рублей, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "На сумму со сроком год", key: "7", a: "9", text: "на ХХХ рублей. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
            { label: "Пицца за опоздание", key: "8", a: "10", text: "на пиццу 35 см, её можно получить 1 раз в течение года. Предложение действует в любом ресторане России. Пиццу можно получить бесплатно, если её стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Предложение суммируется с комбо, но не работает с другими акциями.", textCondition: "" },
           
            { label: "Довоз (предложение)", key: "20", a: "20", text: "Можем привезти ХХХ в течение часа, также подарим скидку 10% за ожидание. Подойдёт такое решение?", textCondition: "" },
            { label: "Довоз (промокод)", key: "21", a: "21", text: "XXX доставим в течение часа, примите пока промокод на скидку 10%: [ХХХ](dodo://applypromocode/ХХХ). Им можно воспользоваться 1 раз в течение 2 недель. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
        ],
        kz: [
            { label: "10-30% на 14 дней", key: "1", a: "1", text: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "1100/2000/3000/5700 тенге на 14 дней", key: "2", a: "2", text: "на 1100/2000/3000/5700 тенге, им можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 1100/3000/5700 тенге, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "Пицца 25-35 см на 14 дней", key: "3", a: "3", text: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить бесплатно, если её стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Промокод суммируется с комбо, но не работает с другими акциями.", textCondition: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить в подарок к заказу на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, предложение суммируется с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе с подарочным продуктом должна быть выше минимальной на доставку." },
            { label: "11500 тенге на год", key: "5", a: false, text: "на 11500 тенге. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 11500 тенге, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "600 тенге на 14 дней", key: "7", a: "5", text: "на 600 тенге, им можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 600 тенге, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "600 тенге на год", key: "8", a: "6", text: "на 600 тенге. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 600 тенге, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "На сумму со сроком год", key: "9", a: "7", text: "на ХХХ тенге. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
            { label: "Пицца за опоздание", key: "10", a: "8", text: "на пиццу 35 см, её можно получить 1 раз в течение года. Предложение действует в любом ресторане Казахстана. Пиццу можно получить бесплатно, если её стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Предложение суммируется с комбо, но не работает с другими акциями.", textCondition: "" },
           
            { label: "Довоз (предложение)", key: "20", a: "20", text: "Можем привезти ХХХ в течение часа, также подарим скидку 10% за ожидание. Подойдёт такое решение?", textCondition: "" },
            { label: "Довоз (промокод)", key: "21", a: "21", text: "XXX доставим в течение часа, примите пока промокод на скидку 10%: [ХХХ](dodo://applypromocode/ХХХ). Им можно воспользоваться 1 раз в течение 2 недель. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },

        ]
    };

    const handlePromoChange = (event, value) => {
        if (value) {
            setSelectedPromo(value);
            updatePromoText(value);
        } else {
            setSelectedPromo(null);
            props.setPromoText("");
        }
    };

    const updatePromoText = (promo) => {
        let promoText = conditions ? promo.textCondition : promo.text;

        if (promoText !== "") {
            if (promo.key !== "20" && promo.key !== "21") {
                if (offerType) {
                    promoText = `Хотим исправиться, отправляем промокод [ХХХ](dodo://applypromocode/ХХХ) ${promoText} Промокод также можно найти в приложении или на сайте ${country === "ru" ? "dodopizza.ru" : "dodopizza.kz"}, в разделе «Акции».`;
                } else {
                    promoText = `Предлагаем удобный вариант, промокод ${promoText} Примете его?`;
                }
            }
        }
        props.setPromoText(promoText);
    };

    const handleCountryChange = (event) => {
        const newCountry = event.target.checked ? "kz" : "ru";
        setCountry(newCountry);
        if (selectedPromo) {
            const promoKey = selectedPromo.key;
            const foundPromo = promos[newCountry].find(promo => promo.a === promoKey);

            if (foundPromo) {
                setSelectedPromo(foundPromo);
                updatePromoText(foundPromo);
            } else {
                setSelectedPromo(null);
                props.setPromoText("");
            }
        }
    };

    const handleConditionsChange = (event) => {
        setConditions(event.target.checked);
        if (selectedPromo) {
            updatePromoText(selectedPromo);
        }
    };

    const handleOfferTypeChange = (event) => {
        setOfferType(event.target.checked);
        if (selectedPromo) {
            updatePromoText(selectedPromo);
        }
    };

    useEffect(() => {
        if (selectedPromo) {
            updatePromoText(selectedPromo);
        }
    }, [conditions, offerType, selectedPromo]);

    return (
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2, marginTop: "10px" }}>
            <Autocomplete
                sx={{ width: "400px" }}
                options={promos[country]}
                getOptionLabel={(option) => option.label}
                onChange={handlePromoChange}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={(params) => <TextField {...params} label="Компенсация" />}
            />
            <FormControlLabel
                control={<Checkbox checked={country === "kz"} onChange={handleCountryChange} />}
                label="Казахстан"
            />
            <FormControlLabel
                control={<Checkbox checked={conditions} onChange={handleConditionsChange} />}
                label="С условием"
            />
            <FormControlLabel
                control={<Checkbox checked={offerType} onChange={handleOfferTypeChange} />}
                label="Отправляем"
            />
        </Box>
    );
}

export default Promocodes;