
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VariableSizeList } from 'react-window';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from "@mui/material/styles";

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

// import { FixedSizeList as List } from 'react-window';
import debounce from 'lodash.debounce';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
const apiUrl = 'https://dodogenerator.pacificrp.ru';


function UserTemplates() {

    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    const [name, setName] = useState('');
    const [tag, setTag] = useState('');
    const [text, setText] = useState('');
    const [editingTemplate, setEditingTemplate] = useState(null);

    const DialogRef = useRef(null);


    const handleSubmit = async () => {
        try {
            if (editingTemplate) {
                const response = await axios.put(`https://dodogenerator.pacificrp.ru/templates/${editingTemplate.id}`, {
                    name,
                    tag,
                    text
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                setTemplates(prevTemplates =>
                    prevTemplates.map(template =>
                        template.id === editingTemplate.id ? response.data : template
                    )
                );
            } else {
                const response = await axios.post('https://dodogenerator.pacificrp.ru/templates', {
                    name,
                    tag,
                    text
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });
                setTemplates(prevTemplates => [...prevTemplates, response.data]);
            }
            handleClose();
        } catch (error) {
            handleClose();
            setError('Ошибка при добавлении/редактировании шаблона');
        }
    };


    const handleDelete = async (templateId) => {
        try {
            await axios.delete(`https://dodogenerator.pacificrp.ru/templates/${templateId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setTemplates(prevTemplates => prevTemplates.filter(template => template.id !== templateId));
        } catch (error) {
            setError('Ошибка при удалении шаблона');
        }
    };

    const handleClose = () => {
        setEditingTemplate(null);
        setOpen(false);
    };

    const handleEdit = (template) => {
        setEditingTemplate(template);
        setName(template.name);
        setTag(template.tag);
        setText(template.text);
        setOpen(true);
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axios.get('https://dodogenerator.pacificrp.ru/templates', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setTemplates(response.data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchTemplates();
    }, []);

    return (
        <>
            <Dialog ref={DialogRef} open={open} maxWidth={false}>
                <DialogTitle sx={{ width: 1000, minWidth: "600px" }} id="alert-dialog-title">
                    <Typography>{editingTemplate ? 'Редактировать шаблон' : 'Добавить шаблон'}</Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 20 }}>
                    {
                        <>
                            <TextField
                                label="Имя"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                label="Тэг"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                            />
                            <TextField
                                multiline
                                rows={16}
                                label="Текст шаблона"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </>
                    }
                </DialogContent>
                <DialogActions>

                    <Button variant={"outlined"} onClick={() => {
                        handleClose();
                    }}>Отмена</Button>

                    <Button variant={"outlined"} onClick={() => {
                        handleSubmit();
                    }}>Ок</Button>

                </DialogActions>
            </Dialog>

            <Container maxWidth="md">
                <Button fullWidth onClick={() => setOpen(true)}>Добавить</Button>
                {error && <Typography color="error">{error}</Typography>}
                <List sx={{ height: '800px', overflowY: "scroll" }}>
                    {templates.map((template) => (
                        <Tooltip title={template.text} arrow placement='left' >
                            <Paper>
                                <ListItem key={template.id}>
                                    <ListItemText
                                        primary={template.name || 'Без названия'}
                                        secondary={template.tag || 'Нет тега'}
                                    />
                                    <IconButton onClick={() => handleEdit(template)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(template.id)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </ListItem>
                            </Paper>
                        </Tooltip>
                    ))}
                </List>
            </Container>
        </>
    );
}

export default UserTemplates;


